<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="{ employee: employee, order: { dateFrom: 'desc' } }"
    >
        <template v-slot:filters="{ busy }">
            <table-filters
                v-model="filters"
                :fields="headers"
                :busy="busy"
                :list-filters="listFilters"
                hide-search-box
            ></table-filters>
        </template>

        <template v-slot:default="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                :action-width="140"
                row-action
                @onRowSelected="onRowSelected"
            >
                <!-- allowance.name -->
                <template v-slot:[`item.allowance.name`]="{ item }">
                    {{ item.allowance.name }}<br />
                    <span class="small">#{{ item.allowance.uniqueId }}</span>
                </template>

                <!-- credit -->
                <template v-slot:[`item.credit`]="{ item }">
                    <span v-show="editedItem !== item">
                        {{ item.credit | currency }}
                    </span>
                    <v-form
                        v-show="editedItem === item"
                        v-model="isValid"
                        @submit.prevent="submit(item)"
                    >
                        <currency-input
                            :key="item['@id']"
                            v-model="item.credit"
                            v-validate="'required|min_value:0|decimal:2'"
                            :name="`${item['@id']}.credit`"
                            label="labels.balance"
                            hide-details="auto"
                            single-line
                            class="pt-0 mt-0"
                            :error-messages="
                                errors.collect(`${item['@id']}.credit`)
                            "
                            :data-vv-as="$t('labels.balance')"
                        ></currency-input>
                    </v-form>
                </template>

                <!-- budget -->
                <template v-slot:[`item.allowance.budgetLimit`]="{ item }">
                    {{ item.allowance.budgetLimit | currency }}
                </template>

                <!-- from -->
                <template v-slot:[`item.dateFrom`]="{ item }">
                    {{ item.dateFrom | toDate('L') }}
                </template>

                <!-- to -->
                <template v-slot:[`item.dateUntil`]="{ item }">
                    {{ item.dateUntil | toDate('L') }}
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <template v-if="editedItem !== item">
                        <edit-button
                            @execute="edit(item, 'credit')"
                        ></edit-button>
                    </template>
                    <template v-else>
                        <v-btn
                            color="primary"
                            class="white-text"
                            :disabled="!isValid"
                            icon
                            @click="submit(item)"
                        >
                            <v-icon>check</v-icon>
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            icon
                            @click="cancel(item, 'credit')"
                        >
                            <v-icon>cancel</v-icon>
                        </v-btn>
                    </template>
                </template>
            </d-table>
        </template>
    </data-iterator>
</template>

<script>
import { mapGetters } from 'vuex'
import RestApiType from '@/api/RestApiType'
import configType from '@/store/type/configType'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import EditInList from '@/components/mixins/EditInList'
import BalancesHeaders from '@/pages/users/mixins/BalancesHeaders'
import BalancesListFilters from '@/pages/users/mixins/BalancesListFilters'

export default {
    components: { CurrencyInput },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        EditInList,
        BalancesHeaders,
        BalancesListFilters,
    ],
    props: {
        userId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.COMPANY_BALANCES,
            mapper: MapperType.COMPANY_BALANCES_FORM,
            filters: {},
            editedItem: null,
            editedOriginal: null,
            isValid: false,
        }
    },
    computed: {
        ...mapGetters({
            currency: configType.getters.CURRENCY_CODE,
        }),
        employee() {
            return RestApiCollection.get(RestApiType.USERS).getItemIri(
                this.userId
            )
        },
    },
    methods: {
        getTitle(item) {
            const from = this.$date(item.dateFrom).format('L')
            const to = this.$date(item.dateUntil).format('L')
            return `${item.allowance.name}: ${from} - ${to}`
        },
    },
}
</script>

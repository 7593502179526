<template>
    <div>
        <page-title>{{ title }}</page-title>
        <user-route-tabs class="mb-4" :user-id="userId"></user-route-tabs>
        <allowance-balances-table :user-id="userId"></allowance-balances-table>
    </div>
</template>

<script>
import SelectApiClienType from '@/api/SelectApiClientType'
import TitleMixinGenerator from '@/components/mixins/page-title/TitleMixinGenerator'
import UserRouteTabs from '@/components/pages/users/RouteTabs'
import AllowanceBalancesTable from '@/components/pages/balances/AllowanceBalancesTable'

export default {
    components: { UserRouteTabs, AllowanceBalancesTable },
    mixins: [TitleMixinGenerator(SelectApiClienType.USER, 'userId')],
    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>

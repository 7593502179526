export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'dateUntil',
                    inputType: 'checkbox',
                    label: 'labels.current_balances',
                    type: 'after',
                    value: this.$date().format('YYYY-MM-DD'),
                    other: {
                        key: 'dateFrom',
                        type: 'before',
                        value: this.$date().format('YYYY-MM-DD'),
                    },
                    default: true,
                },
            ]
        },
    },
}

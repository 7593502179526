export default {
    computed: {
        headers() {
            return [
                {
                    value: 'allowance.name',
                    text: this.$t('labels.name'),
                    sortable: false,
                },
                {
                    value: 'dateFrom',
                    text: this.$t('labels.from'),
                    sortable: false,
                    width: 140,
                },
                {
                    value: 'dateUntil',
                    text: this.$t('labels.to'),
                    sortable: false,
                    width: 140,
                },
                {
                    value: 'allowance.budgetLimit',
                    text: this.$t('labels.budget'),
                    sortable: false,
                    width: 180,
                },
                {
                    value: 'credit',
                    text: this.$t('labels.balance'),
                    sortable: false,
                    width: 180,
                },
            ]
        },
    },
}
